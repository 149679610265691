<template>
  <div id="AirwayBillPoDetail">
    <v-container>
      <v-card>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Detail Pesanan Barang
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>Nomor PO</v-list-item-title>
            <v-list-item-subtitle class="text-right font-weight-bold">
              {{ getDetailsFilter.po_number }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Nama Pemesan</v-list-item-title>
            <v-list-item-subtitle class="text-right font-weight-bold">
              {{ getDetailsFilter.order_name }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Alamat Pemesan</v-list-item-title>
            <v-list-item-subtitle class="text-right font-weight-bold">
              {{ getDetailsFilter.order_address }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Jumlah Barang</v-list-item-title>
            <v-list-item-subtitle class="text-right font-weight-bold">
              {{ getDetailsFilter.total_item }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item class="mt-5">
            <template>
              <v-layout child-flex>
                <v-data-table
                  dense
                  :headers="headerDetail"
                  :items="getDetailsFilter.detail"
                  mobile-breakpoint="0"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>{{ props.item.product_name }}</td>
                      <td>{{ props.item.product_code }}</td>
                      <td>{{ formatAmount(props.item.quantity_order) }}</td>
                      <td>{{ formatRupiah(props.item.price_order) }}</td>
                      <td>{{ formatRupiah(props.item.sub_total) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  props: ["getDetailsFilter"],
  mixins: [globalMixin],

  data() {
    return {
      headerDetail: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah Barang", sortable: false },
        { text: "Harga Satuan", sortable: false },
        { text: "Sub Total", sortable: false }
      ]
    };
  }
};
</script>

<style>
#AirwayBillPoDetail table td {
  border: 1px solid #ddd;
}
</style>
