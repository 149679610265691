<template>
  <div id="ManageSupplier">
    <v-container>
      <v-card class="secondary px-10 py-5">
        <v-card-title class="text-h5 mb-5">Buat Surat Jalan</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="airwayBill.poSelect"
                  :items="getSupplierAirwayBillPurchaseOrders"
                  item-value="id"
                  label="Pilih Nomor PO*"
                  @change="getDataDetail"
                  :rules="poRules"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.po_number }} -
                    {{ data.item.store_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.po_number }} -
                    {{ data.item.store_name }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="isFound">
              <v-col cols="12">
                <div>
                  <po-detail
                    :getDetailsFilter="getSupplierAirwayBillPurchaseOrderDetail"
                  ></po-detail>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Expedisi*"
                  v-model="airwayBill.expedition"
                  clearable
                  :rules="expeditionRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Masukkan Nomor Kendaraan*"
                  v-model="airwayBill.vehicleNumber"
                  clearable
                  :rules="vehicleNumberRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Masukkan Jenis Kendaraan*"
                  v-model="airwayBill.vehicleType"
                  clearable
                  :rules="vehicleTypeRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Masukkan Nama Pengemudi*"
                  v-model="airwayBill.driverName"
                  clearable
                  :rules="driverRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-menu
                  v-model="menuShippingDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="airwayBill.shippingDate"
                      label="Masukkan Tanggal Pengiriman*"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :rules="dateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="airwayBill.shippingDate"
                    @input="menuShippingDate = false"
                    :min="min"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6" align="right">
              <v-btn
                class="mr-5"
                color="error"
                :disabled="getLoading"
                @click="onCancelClick"
                text
                >Batal</v-btn
              >
              <v-btn
                color="success"
                :disabled="getLoading"
                :loading="getLoading"
                @click="onSubmitClick"
                >Kirim
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import PoDetail from "@/components/Widget/supplier/AirwayBillPoDetail";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PoDetail
  },

  data() {
    return {
      valid: true,
      headers: [
        { text: "Nama Supplier", sortable: false },
        { text: "Kode Supplier", sortable: false },
        { text: "Alamat", sortable: false },
        { text: "Status", sortable: false },
        { text: "Aksi", sortable: false }
      ],
      airwayBill: {
        poSelect: "",
        expedition: "INTERNAL",
        vehicleNumber: "",
        vehicleType: "",
        driverName: "",
        shippingDate: ""
      },
      isFound: false,
      menuShippingDate: false,
      poRules: [v => !!v || "Nomor PO wajib diisi"],
      expeditionRules: [v => !!v || "Expedisi wajib diisi"],
      vehicleNumberRules: [v => !!v || "Nomor Kendaraan wajib diisi"],
      vehicleTypeRules: [v => !!v || "Jenis Kendaraan wajib diisi"],
      driverRules: [v => !!v || "Nama Supir wajib diisi"],
      dateRules: [v => !!v || "Tanggal wajib diisi"],
      min: new Date().toISOString().slice(0, 10)
    };
  },

  computed: {
    ...mapGetters(["getLoading"]),
    ...mapGetters("airwayBill", ["getSupplierAirwayBillPurchaseOrders"]),
    ...mapGetters("airwayBill", ["getSupplierAirwayBillPurchaseOrderDetail"])
  },

  mounted() {
    this.getPurchaseOrders();
  },

  methods: {
    ...mapActions("airwayBill", ["getPurchaseOrders"]),

    getDataDetail() {
      const payload = {
        id: this.airwayBill.poSelect
      };

      this.$store
        .dispatch("airwayBill/getPurchaseOrderDetail", payload)
        .then(() => (this.isFound = true))
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        });
    },
    onSubmitClick() {
      this.$refs.form.validate();

      if (
        this.airwayBill.poSelect === "" ||
        this.airwayBill.expedition === "" ||
        this.airwayBill.vehicleNumber === "" ||
        this.airwayBill.vehicleType === "" ||
        this.airwayBill.driverName === "" ||
        this.airwayBill.shippingDate === ""
      ) {
        this.$tostini({
          message: "Data Wajib diisi!",
          type: "error"
        });
        return;
      }

      const payload = {
        po_id: this.airwayBill.poSelect,
        expedition: this.airwayBill.expedition,
        vehicle_number: this.airwayBill.vehicleNumber,
        vehicle_type: this.airwayBill.vehicleType,
        driver_name: this.airwayBill.driverName,
        shipping_date: this.airwayBill.shippingDate
      };

      this.$refs.form.validate();

      this.$store
        .dispatch("airwayBill/addAirwayBill", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });

          this.$router.push("/airwayBillSupplier");
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.refreshData();
        });
    },
    onCancelClick() {
      this.$router.push("/airwayBillSupplier");
    }
  }
};
</script>
